/*
* API 统一管理。使用时按需导入。
* 团队开发可以创建多个模块分别引入axioms index.us. 管理自己的接口。
* 使用：
* 组件内，导入api.js 
* import {apiAddress} from “./api.js”
* 
*/
import qs from 'qs'; // qs模块，用来序列化post类型的数据
import { get, post } from './http'

// export const apiAddress = p => post('api/v1/users', p);

//新闻模块请求
export const article = {    
    //无参数           
    getNewsData () { 
        return get('api/get_news_data');    
    },    
    // 带参数    
    articleDetail (id, params) { 
        return get('api/v1/users', {            
            params: params        
        });    
    },

}

//登录模块请求
export const getLogin = {
    // post提交    
    login (params) {     
        return post('api/login', params);    
    },
    //注册接口
    Registered (params) {        
        return post('api/register/add', params);    
    },
    //忘记密码接口
    Psword (params) {
        return post('api/password/reset', params);    
    },

    // 其他接口………
    
	// 新闻详情
	getNewsDetails(params) {
	    return post('api/get_news_details', params);
	},
	// 修改密码
	getNewsPassword(params) {
	    return post('api/password/update', params);
	},
	// 动态文件获取
	getFile(params) {
	    return post('api/get_official_content_data', params);
	},
    //上传文件信息
    record(params) {
	    return post('api/file/info/record', params);
	},
        //上传文件记录
    Document(params) {
        return post('api/file/info/select', params);
    },
}





//其它模块


export const userInfo = {
    //已审核用户
    selsetUser(params) {
        return post('api/sign_up/select', params);
    },
    //未审核用户
    notSelsetUser(params) {
        return post('api/payment/select', params);
    },
    //删除
    deleteUser(params) {
        return post('api/user/delete', params);
    },
    //用户保存
    saveUser(params) {
        return post('api/user/save', params);
    },
    //用户信息导入
    importUinfo(params) {
        return post('api/sign_up/add', params);
    },
    //用户信息导出
    exportUinfo(params) {
        return post('api/user/info/export', params);
    },
    //用户信息导入
    erroDownload(params) {
        return get('api/data/download', params);
    },
    //发票
    invoice(params) {
        return post('api/payment/add', params);
    }
}

//报名首页接口
export const getHome = {
	getSelect(params) {
	    return post('api/sign_up/info/select', params);
	},
}