import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
// import Home from '../views/Home.vue';
import Master from '../back/master/index.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/HomePage',
    meta: {
      keepAlive: true, //此组件需要被缓存
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/webSocket',
    name: 'webSocket',
    component: () => import('../views/webSocket.vue')
  },
  {
    path: '/HomePage',//首页
    name: 'HomePage',
    component: () => import('../views/Home/Home.vue')
  },
  {
    path: '/challenge',//挑战杯
    name: 'challenge',
    component: () => import('../views/challenge/challenge.vue')
  },
  // {
  //   path: '/HomePage',//首页
  //   name: 'HomePage',
  //   component: () => import('../views/HomePage/HomePage.vue')
  // },
  {
    path: '/Contact',//联系我们
    name: 'Contact',
    component: () => import('../views/Contact/Contact.vue')
  },
  {
    path: '/File',//官方文件
    name: 'File',
    component: () => import('../views/File/File.vue')
  },
  {
    path: '/FileDetails',//官方文件
    name: 'details',
    component: () => import('../views/File/details.vue')
  },

  {
    path: '/Train',//大赛培训
    name: 'Train',
    component: () => import('../views/Train/Train.vue')
  },
  {
    path: '/Seventh',//第七届大唐杯官方文件总汇
    name: 'Seventh',
    component: () => import('../views/File/Seventh/Seventh.vue')
  },
  {
    path: '/Eighth',//第八届大唐杯官方文件总汇
    name: 'Eighth',
    component: () => import('../views/File/Eighth/Eighth.vue')
  },
  {
    path: '/Ninth',//第九届大唐杯官方文件总汇
    name: 'Ninth',
    component: () => import('../views/File/Ninth/Ninth.vue')
  },
  {
    path: '/upload',//赛事介绍
    name: 'upload',
    component: () => import('../views/innovation/innovation.vue')
  },
  {
    path: '/fingerpost',//参赛指南
    name: 'fingerpost',
    component: () => import('../views/fingerpost/fingerpost.vue')
  },
  {
    path: '/Brief',//大赛简介
    name: 'Brief',
    component: () => import('../views/Brief/Brief.vue')
  },
  {
    path: '/Framework',//组织架构
    name: 'Framework',
    component: () => import('../views/Framework/Framework.vue')
  },
  {
    path: '/Retrospect',//往届回顾
    name: 'Retrospect',
    component: () => import('../views/Retrospect/Retrospect.vue')
  },
  {
    path: '/Journalism',//新闻动态
    name: 'Journalism',
    component: () => import('../views/Journalism/Journalism.vue')
  },
  {
    path: '/Platform',//平台介绍
    name: 'Platform',
    component: () => import('../views/Platform/Platform.vue')
  },
  {
    path: '/Regulations',//大赛章程
    name: 'Regulations',
    component: () => import('../views/Regulations/Regulations.vue')
  },
  {
    path: '/Register',//登录注册
    name: 'Register',
    component: () => import('../views/register/index.vue')
  },
  {
    path: '/back',//注册
    name: 'back',
    component: () => import('../views/register/back.vue')
  },
  {
    path: '/registered',//找回密码
    name: 'registered',
    component: () => import('../views/register/registered.vue')
  },
  {
    path: '/ViewDetail',//大赛简介
    name: 'ViewDetail',
    component: () => import('@/views/ViewDetail/Brief.vue')
  },
  {
    path: '/TeachersGet',//教师赛页面
    name: 'TeachersGet',
    component: () => import('../views/Teachers/Teachers.vue')
  },
  {
    path: '/signup',//教师赛登录
    name: 'signup',
    component: () => import('../views/Teachers/signup.vue')
  },
  {
    path: '/innovation',//创新赛页面
    name: 'innovation',
    component: () => import('../views/innovation/innovation.vue')
  },
  //后台管理页面
  {
    path: '/backHome',
    name: 'backHome',
    component: () => import('../back/home/index.vue'),
    redirect: "/Master",
    children: [
      {
        path: '/Teachers',
        name: 'Teachers',
        title:'报名管理',
        component: () => import('../back/management/index.vue'),
      },
      {
        path: '/ViewDetails',
        name: 'ViewDetails',
        title:'缴费信息',
        component: () => import('../back/step/index.vue')
      },
      {
        path: '/Info',
        name: 'Info',
        component: () => import('../back/inforManagement/index.vue')
      },
      {
        path: '/Master',
        name: 'Master',
        component: Master
      },
    ]
  },

  {
    path: '/Enrollment',//报名管理
    name: 'Enrollment',
    component: () => import('../back/management/index.vue')
  },
  // {
  //   path: '/Step',//下一步
  //   name: 'Step',
  //   component: () => import('../back/step/index.vue')
  // },
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from, next) => {
  // let token = window.localStorage.getItem("userToken");
    if(to.path === '/Master'){
      setTimeout(() => {
        let obj = JSON.parse(localStorage.getItem("UPuser"))
        let uid = obj.user_id
      }, 1000);
    }



  // if(!token) {
  //   if(to.path === '/Register') {
  //     return next()
  //   }
  // } else {
  //   next('/backHome')
  // }
  window.scrollTo(0, 0);
});

export default router
