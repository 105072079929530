import {createStore} from 'vuex'

/**
 * state 存放所有的变量集合
 * 使用 UPDATA 进行提交更改
 */
export default createStore({
	
	state: {
		list: JSON.parse(localStorage.getItem("UPDATA")) || [],
		num:10002,
		User: JSON.parse(localStorage.getItem("UPuser")) || "",
		apload:"",
	},
	mutations: {
		UPDATA(state, obj) {
			state.list.unshift(obj);
			//本地持久化
			localStorage.setItem("UPDATA", JSON.stringify(state.list))
		},
		UPuser(state, obj) {
			state.User = obj;
			//本地持久化
			localStorage.setItem("UPuser", JSON.stringify(state.User))
		}
	},
	modules: {

	}
})
