<template>
  <div id="nav">
	  <router-view/>
  </div>
  
</template>

<style lang="scss">
	#nav{
		height: 100%;
	}
</style>
