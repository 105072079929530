<template>
	<div>
		<div class="warper">
			<div class="header">
				<h2><span>●</span>&nbsp;常用功能</h2>
				<div class="btn-item">
					<router-link to="/Teachers">
						<div style="background-color: #C45454; color: white;">报名信息上传</div>
					</router-link>
				<!-- <div>报名信息修改</div>
					<div>发票信息登记/修改</div>
					<div>证书邮寄地址登记</div>
					<div>监考视频上传</div>
					<div>个人信息修改</div>
					<div>证书邮寄地址登记</div> -->
				</div>
			</div>
			<div class="main">
				<div class="item-left">
					<div class="item-title">
						<h2><span>●</span>&nbsp;赛事日历</h2>
					</div>
					<div class="item-conter">
						<div class="time">
							<!-- {{time}} -->
							<el-calendar v-model="time" />
						</div>
						<div class="calendar">
							<div class="item-card">2022年12月15日 <span>报名开始</span></div>
							<div class="item-card">2023年3月10日 <span>报名结束</span></div>
							<!-- <div class="item-card">2023年4月中下旬 <span>大唐杯省赛</span></div>
							<div class="item-card">2023年6月 <span>大唐杯全国总决赛</span></div> -->
						</div>
					</div>
				</div>
				<div class="item-middle">
					<div class="item-title">
						<h2><span>●</span>&nbsp;报名信息</h2>
					</div>
					<div class="item-conter">
						<div class="msg-card blue">
							<p>已报名学生</p>
							<ul>
								<li><span>●</span>&nbsp;已上传学生人数: {{this.submit.submit_count}}</li>
								<li><span>●</span>&nbsp;报名成功人数: {{this.submit.succeed_count}}</li>
							</ul>
						</div>
						<!-- <div class="msg-card green">
							<p>省赛获奖信息</p>
							<ul>
								<li><span>●</span>&nbsp;省赛一等奖人数：0</li>
								<li><span>●</span>&nbsp;省赛二等奖人数：0</li>
								<li><span>●</span>&nbsp;省赛三等奖人数：0</li>
								<li><span>●</span>&nbsp;省赛入围人数：0</li>
							</ul>
						</div> -->
						<!-- <div class="msg-card orang">
							<p>国赛获奖信息</p>
							<ul>
								<li><span>●</span>&nbsp;国赛一等奖人数：0</li>
								<li><span>●</span>&nbsp;国赛二等奖人数：0</li>
								<li><span>●</span>&nbsp;国赛三等奖人数：0</li>
							</ul>
						</div> -->
						<!--
						<div class="msg-card pink">
							<p>组别信息</p>
							<ul>
								<li><span>●</span>&nbsp;本科A组：0</li>
								<li><span>●</span>&nbsp;本科B组：0</li>
							</ul>
						</div>
						-->
					</div>
				</div>
				<div class="item-right">
					<div class="item-title">
						<h2><span>●</span>&nbsp;我的信息</h2>
					</div>
					<div class="item-conter">
						<div class="my-msg-card">
							<ul>
								<li>发票信息：未登记</li>
								<li>发票抬头：无</li>
								<li>发票税号：无</li>
								<li>发票类型：未选择</li>
							</ul>
						</div>
						<div class="my-msg-card">
							<ul>
								<li>获奖证书邮寄地址：未登记</li>
								<li>收件地址：无</li>
								<li>收货人：无</li>
								<li>收货人电话：无</li>
							</ul>
						</div>
						<div class="my-msg-card">
							<ul>
								<li>监考视频上传：（线上参赛）</li>
								<li>省赛监考视频：未上传</li>
								<li>国赛监考视频：未上传</li>
							</ul>
						</div>

						<div class="my-msg-card">
							<p style="font-size: .20rem;font-weight: bold;margin-bottom: 0.15rem;">文件下载</p>
							<ul>
								<li><span style="color: #409EFF;">●</span>&nbsp;<el-link type="primary" @click="Download">大唐杯参赛委托代收证明下载</el-link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">

			</div>
		</div>
	</div>
</template>


<script>
	import { getHome } from "api/apis.js";
	import { ElMessage } from 'element-plus'
	import {Search} from '@element-plus/icons-vue'
	export default {
		name: 'master',
		props: {
			// msg: String
		},
		components: {
			// Header,Footer
		},
		data() {
			return {
				Search: Search,
				time: new Date(),
				fileList: [],
				url: '',
				submit:{}
			}
		},
		created() {
			this.getSelect();
		},
		methods: {
			//报名信息统计
			getSelect(){
			    let uid = this.$store.state.User.user_id
				console.log(uid)
			    let dataobj = new FormData();
			    dataobj.append("user_id", uid);
			    getHome.getSelect(dataobj).then(res => {
					this.submit = JSON.parse(res.data);
					// console.log(JSON.parse(res.data))
			    })
			},
       		// 文件下载
			Download(){
				window.open('./static/ZhenM/第十届大唐杯参赛费代收证明.pdf')
			}
		},
	}
</script>

<style scoped lang="scss">
	:deep(.el-calendar-table td.is-selected) {
		color: #fff;
		background-color: #0256FF;
		// border-radius: 50%;
	}

	:deep(.el-calendar-table td.is-today) {
		color: #fff;
		background-color: #0256FF;
		// border-radius: 50%;
	}

	:deep(.el-calendar-day) {
		height: 0.45rem !important;
		text-align: center;
	}

	:deep(.el-calendar-table .el-calendar-day:hover) {
		color: #fff;
		background-color: #0256FF;
		// border-radius: 50%;
	}
	.router-link-active {
	  text-decoration: none;
	}

	@import './index.scss';
</style>
